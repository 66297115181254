.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.register-banner {
  height: 400px;
  /*background-image: url('./assets/static/images/banner-home.jpg');*/
  background-color: #f29600;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.MuiButton-containedSecondary {
  color: white !important;
  background-color: #133459 !important;
}

.consent-button {
  background-color: #265623 !important;
}

.MuiButton-outlinedSecondary {
  color: rgba(9, 9, 9, 0.81) !important;
  border: 1px solid rgb(3, 3, 3) !important;
}

.makeStyles-heroContent-2 {
  margin-top: 40px !important;
}

.container {
  margin-top: 90px;
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .4rem 1rem;
  margin-bottom: 2rem;
  list-style: none;
  border-radius: 0rem;
  font-size: 0.8rem;
  font-weight: bolder;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

a {
  color: #3366FF;
}

.MuiTypography-body1 {
    color: #242424 !important;
}

.MuiButton-label {
    font-weight: 700 !important;
}

.offer {
  color: #f5c300 !important;
}

.center {
  padding-left: 0px;
  text-align: center;
  list-style-position: inside;
}

.MuiTypography-colorTextSecondary {
    color: #242424 !important;
}

.MuiCardHeader-title {
  font-weight: 700 !important;
  font-size: 1.4rem !important;
}

.logo {
  font-family: 'Great Vibes', cursive;
}

.page-header {
  background-color: #133459;
  padding: 0.0em 0.2em 0.0em 0.2em
}

.material-icons{
  display: inline-flex;
  vertical-align: top;
}

@media screen and (max-width: 644px) {
  .material-icons {
    font-size: 1rem !important;
  }
}

.material-icons-done{
  display: inline-flex;
  vertical-align: top;
  font-size: 26px;
}

.menu-buttons {
  padding: 0px 40px !important;
  text-transform: none !important;
  color: #133459 !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}

.typewriter {
  color: #fff;
  font-family: monospace;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0;
  margin-right: auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;
}


/* The typing effect */

@keyframes typing {
  from {
    width: 50%
  }
  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

.hr-line {
  border-color: grey;
  background-color: grey;
  color: grey;
  margin-top: 16px;
  width: 25%;
}

.mui-buttons {
  font-weight: 600 !important;
}

.disappear {
  display: none !important;
}

.text-primary {
  text-align: left;
}

/* Custom form styles */
.my-custom-form {
  padding-bottom: 40px;
  /* Add your form styling here */
}

/* Input field styles */
.custom-input {
  border: 1px solid #424242; /* Change the border color as needed */
  padding: 4px;
  min-width: 100%;
  margin-top: 4px;
  border-radius: 4px;
  /* Add any other input field styling here */
}

/* Label styles (for labels above input fields) */
.form-group label {
  display: block; /* Labels on their own line */
  /* Add any other label styling here */
}

/* CSS to display radio buttons on the same line */
.inline-radio {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between radio buttons as needed */
}

.form-row {
  padding: 8px 0px;
}

.fieldset-custom {
  border-radius: 4px;
  border: 0px;
}

.invalid-feedback {
  color: red;
}

.av-field-input.valid {
  border-color: green; /* Border color when the input is valid */
}

.terms {
  font-size: 0.8rem !important;
}
